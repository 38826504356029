<template>
    <div class="filtro">
        <span class="borde" :style="getStyle()"></span>
        <button :class="['accordion', {active: irekia != 0}]" @click="irekiItxi">
            <span class="titulo">
                {{ item.titulo }}
                <span class="icono-txiki">
                    <span v-for="(icono, index) in item.iconos" :key="index">
                        <!-- <img :src="'img/' + icono"> -->
                        <img :src="icono">
                    </span> 
                </span>
            </span>
        </button>
        <div class="panel" :id="'panel' + item.id" :style="{'max-height': irekia + 'px'}">
            <ul>
                <li v-for="atala in item.atalak" :key="atala.id">
                    <button type="button" class="btn-marcadores" @click="toggleMarcadores({id: atala.id, activo: atala.activo})">
                        <span :class="['fondo-marcadores',{active: atala.activo}]" :style="{'background-color': atala.color}"></span>

                        <span class="icono-activo" v-if="atala.activo && atala.markak.length > 0">
                            <!-- <img :src="'img/' + atala.markak[0].icono"> -->
                            <img :src="atala.markak[0].icono">
                        </span>
                        <span class="icono-activo" v-else-if="atala.activo">
                            <!-- <img :src="'img/' + atala.ibilbideak[0].icono"> -->
                            <img :src="atala.ibilbideak[0].icono">
                        </span>

                        <span class="icono-inactivo" v-else>
                            <span class="bola" :style="{'border-color': atala.color}"></span>
                            <span class="icono">
                                <!-- <img :src="'img/' + atala.icono" class="svg"> -->
                                <img :src="atala.icono" class="svg">
                            </span>
                        </span>
                        {{atala.atalarenizena}}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>

    import { ref, defineProps, defineEmits } from 'vue'

    const irekia = ref(0);

    const props = defineProps({
            item: {
                type: Array,
                required: true
            }
        });

    const emits = defineEmits(['atala']);

    function irekiItxi() {
        let elPanel = document.getElementById("panel" + props.item.id);
        if(irekia.value == 0) irekia.value = elPanel.scrollHeight;
        else irekia.value = 0;
    }

    function toggleMarcadores(cual) {
        emits('atala',{id: cual.id, activo: !cual.activo});
    }

    function getStyle() {
        let colores = [];
        props.item.atalak.forEach(atala => {
            colores.push(atala.color);
        })
        return "background-image: linear-gradient(to bottom, " + colores.join(", ") + ")";
    }
</script>