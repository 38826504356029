<template>
    <div class="mapa">

        <button type="button" v-if="!full" @click="fullScreen" class="gm-control-active btn-fullscreen" style="background: none rgb(255, 255, 255); border: 0px; margin: 10px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; border-radius: 2px; height: 40px; width: 40px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; overflow: hidden; top: 0px; right: 0px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"></button>
        <button type="button" v-else @click="fullScreen" class="gm-control-active btn-fullscreen" style="background: none rgb(255, 255, 255); border: 0px; margin: 10px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; border-radius: 2px; height: 40px; width: 40px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; overflow: hidden; top: 0px; right: 0px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M4%204H0v2h6V0H4v4zm10%200V0h-2v6h6V4h-4zm-2%2014h2v-4h4v-2h-6v6zM0%2014h4v4h2v-6H0v2z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M4%204H0v2h6V0H4v4zm10%200V0h-2v6h6V4h-4zm-2%2014h2v-4h4v-2h-6v6zM0%2014h4v4h2v-6H0v2z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"><img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M4%204H0v2h6V0H4v4zm10%200V0h-2v6h6V4h-4zm-2%2014h2v-4h4v-2h-6v6zM0%2014h4v4h2v-6H0v2z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"></button>

		<div class="logoak">
			<div class="logobat"><img src="@/assets/logo-bizkaia.png"></div>
			<div class="logobat"><img src="@/assets/logo-jaurlaritza.png"></div>
		</div>
        
        <GMapMap id="map" class="map" :center="center" :zoom="13" :options="{mapId: 'b5f508fd536c8f42'}" :key="render">
          <GMapMarker :position="kokapena" />

          <template v-for="(m, index) in arrayMarkak" :key="index">

          <!-- <GMapMarker v-if="m.activo && 'position' in m" :icon="'img/' + m.icono" :position="m.position" @mouseover="irekiInfoa(m.id)" @mouseout="itxiInfoa()" @click="markaClick(m.id)"> -->
          
          <GMapMarker v-if="m.activo && 'position' in m" :icon="m.icono" :position="m.position" @mouseover="irekiInfoa(m.id)" @mouseout="itxiInfoa()" @click="markaClick(m.id)">
            <GMapInfoWindow :opened="openedMarkerID === m.id">
              <div>{{ m.name }}</div>
            </GMapInfoWindow>
          </GMapMarker>

          <GMapPolyline v-else-if="m.activo" :path="m.path" :options="m.options" :editable="false" ref="polyline" />


          </template>

        </GMapMap>
        
    </div>
</template>
<script setup>
import {ref, defineProps, defineEmits, watch } from 'vue';

 import useFullScreen from '@/composables/useFullScreen';

const {full, isFullscreen, requestFullscreen, exitFullscreen} = useFullScreen();

const props = defineProps({
        atala: {
            type: Object,
            required: true
        },
        herriak: {
            type: Array,
            required: true
        },
        filtroak: {
            type: Array,
            required: true
        },
        herria: {
            type: Object,
            required: true
        },
        filtroakezabatu: {
            type: Boolean,
            required: true
        },
        kokapena: {
            type: Object,
            required: true
        },
        nonOrain: {
            type: String,
            required: true
        },
        cargado: {
            type: Boolean,
            required: true
        }
    });

    const emits = defineEmits(['markaClick','zenbataktibo']);

    const arrayMarkak = ref([]);
    /* const full = ref(false); */
    const openedMarkerID = ref(null);

    const center = ref({ lat: 43.156182013403104, lng: -2.588736318797472 });
    const render = ref(0);

    watch(() => props.atala, (newValue, oldValue) => {

      //console.log(props.atala);

        let arrayAtalak = arrayMarkak.value.filter(x => x.atalaid === newValue.id);
        arrayAtalak.forEach(element => {
          const indexHerria = props.herriak.findIndex(x => x.id === element.herria);
          if(props.herriak[indexHerria].activo) element.activo = newValue.activo;
        });
      aktiboakZenbatu();
    });
    watch(() => props.herria, (newValue, oldValue) => {

        for (let x = 0; x < arrayMarkak.value.length; x++) {
          if(arrayMarkak.value[x].herria == newValue.id) { 
            props.filtroak.forEach(element => {
                element.atalak.forEach(atala => {
                    if(atala.id == arrayMarkak.value[x].atalaid && atala.activo) arrayMarkak.value[x].activo = newValue.activo;
                });
            });
          }
        }


      aktiboakZenbatu();
    });
    watch(() => props.filtroakezabatu, (newValue, oldValue) => {
    
      if(newValue) {
        for (let x = 0; x < arrayMarkak.value.length; x++) {
          arrayMarkak.value[x].activo = false;
        }
      }
      aktiboakZenbatu();
    });

    watch(() => props.nonOrain, (newValue, oldValue) => {

    
      if(oldValue != newValue) {

        /* console.log("non orain")
          console.log(oldValue + ' - ' + newValue); */


            for (let x = 0; x < arrayMarkak.value.length; x++) {
              
              if(newValue == arrayMarkak.value[x].herria) {
                arrayMarkak.value[x].activo = true;
              } 
              else {
                arrayMarkak.value[x].activo = false;
              }
            }
            aktiboakZenbatu();

            // forzar reactividad
            render.value = render.value++;
        
       
      }
      
    });

    watch(() => props.cargado, (newValue, oldValue) => {
      if(newValue) {
        mapaBete();
      }
    });

function mapaBete() {

  console.log("mapa bete");
  console.log(props.filtroak);

  arrayMarkak.value = [];
  
    for (let filtroa of props.filtroak) {
          for (let atala of filtroa.atalak) {
              let atalaid = atala.id;

              for (let marka of atala.markak) {
                var pos = { lat: marka.lat, lng: marka.lng };
                var markaBat = {};
                markaBat.id = marka.id;
                markaBat.name = marka.markarenizena;
                markaBat.icono = marka.icono;
                markaBat.herria = marka.herria;
                markaBat.activo = marka.activo;
                markaBat.atalaid = atalaid;
                markaBat.position = pos;
                arrayMarkak.value.push(markaBat);
              }

              if(atala.ibilbideak.length > 0) {
                for (let ibilbidea of atala.ibilbideak) {
                  var ibilbideBat = {};
                  ibilbideBat.id = ibilbidea.id;
                  ibilbideBat.name = ibilbidea.ibilbidearenizena;
                  ibilbideBat.herria = ibilbidea.herria;
                  ibilbideBat.activo = ibilbidea.activo;
                  ibilbideBat.atalaid = atalaid;
                  ibilbideBat.path = ibilbidea.path;
                  ibilbideBat.options = ibilbidea.options;
                  arrayMarkak.value.push(ibilbideBat);
                }
              }
            
        }
    }

}

function irekiInfoa(id) {
  
    openedMarkerID.value = id;
}

function itxiInfoa() {
    openedMarkerID.value = -1;
}

function markaClick(id) {
  emits('markaClick', id);
}

function aktiboakZenbatu() {
  let zenbatAktibo = 0;
  arrayMarkak.value.forEach(marka => {
        if(marka.activo && marka.position != undefined) zenbatAktibo++;
  });
  emits('zenbataktibo', zenbatAktibo);
}

function fullScreen() {
    if (isFullscreen(app)) {
        exitFullscreen();
      } else {
        requestFullscreen(app);
      }
}


//mapaBete();
</script>